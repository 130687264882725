$font-family-sans-serif:  "Roboto", Helvetica, Arial, sans-serif !default;
$font-size-base: 0.9rem !default;

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto');


.brand_name{
    font-size:10rem
}

@media only screen and (max-width: 600px) {
    .brand_name{
        font-size: 3rem
    }
}